import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BannerButton, Banner, HomeHeader } from "../utils"
import SuccessImg from "../images/BGgamepads28.png"
import { Link } from "gatsby"

const SuccessPage = () => (
  <Layout>
    <SEO title="Contact" />
    <HomeHeader img={SuccessImg}>
      <Banner title="" subtitle="Your submission was successful!">
        <Link to="/" style={{ textDecoration: "none" }}>
          <BannerButton Linkstyle={{ margin: "2rem auto" }}>Home</BannerButton>
        </Link>
      </Banner>
    </HomeHeader>
  </Layout>
)
export default SuccessPage
